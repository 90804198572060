





















































import { Component, Vue } from "vue-property-decorator";
import { List, Toast } from "vant";
import RecyleCard from "@/views/recyle/components/RecyleCard.vue";
import {getRecycle} from "@/api/user-supply.api";
import EmptyBox from "@/components/EmptyBox.vue";
import { uniq, differenceBy } from "lodash";

@Component({
  components: {
    RecyleCard,
    EmptyBox,
    List,
  },
})
export default class Recyle extends Vue {
  supply: any = [];
  page = 0;
  page_size = 50;
  finished = false;
  loading = false;
  tip = "从标识支持回收的补给箱中获得的未拆封补给才会出现在这里哦～";

  created() {
    this.load();
  }

  async load() {
    this.page++;
    let res: any = await getRecycle(this.page,this.page_size);
    this.loading = false;
    if (res.length < 10) {
      this.finished = true;
    }
    const rows = [...this.supply, ...res].map((item) => ({
      uniq_id: `${item.id}`,
      ...item,
    }));
    this.supply = differenceBy(rows, (v: any) => v.uniq_id);
  }

  nowList: string[] = [];
  data_list = []; // 列表  [ id ]
  current_ids: string[] = []; // id list
  data_item_click(data: any) {
    const new_id = `${data.id}`;
    const status = this.current_ids.includes(new_id);
    const len = this.current_ids.length;
    if (len >= 8 && !status) {
      Toast("选择补给一次最多8个");
      return;
    }
    if (status) {
      this.current_ids = this.current_ids.filter((cid) => cid !== new_id);
    } else {
      this.current_ids = [new_id, ...this.current_ids];
    }
  }

  toRecyle() {
    if (this.current_ids.length > 0) {
      return this.$router.push({
        name: "recyleDetail",
        query: {
          nowList: this.current_ids.join(),
        },
      });
    } else {
      return;
    }
  }
}
